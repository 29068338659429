<template>
    <div>
        <zw-input-group v-model="inputVal.percent"
                        name="percent"
                        :label-prefix="labelPrefix"
                        validate="required"
                        type="number"
        ></zw-input-group>

        <zw-select-group v-model="inputVal.header_id"
                       :options="headers"
                       name="header"
                       :label-prefix="labelPrefix"
                       class="mb-3"
        ></zw-select-group>

        <zw-select-group v-model="inputVal.footer_id"
                       :options="footers"
                       name="footer"
                       :label-prefix="labelPrefix"
                       class="mb-3"
        ></zw-select-group>

        <b-form-checkbox v-model="inputVal.send">{{ $t('settings.workflow.label.send_email') }}</b-form-checkbox>

        <b-row v-if="inputVal.send">
            <b-col cols="12">
                <zw-select-group v-model="inputVal.template"
                                 :options="getEmailTemplates()"
                                 name="template"
                                 :label-prefix="labelPrefix"
                                 text-field="name"
                                 value-field="id"
                                 validate="required"
                ></zw-select-group>
            </b-col>
        </b-row>
    </div>

</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'GenerateInvoiceOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEmailTemplates']),
        ...mapGetters('Templates', ['getFooters', 'getHeaders']),
    },
    mounted() {
        const templates = this.$store.dispatch('Templates/fetchTemplates', 'rechnung')
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        headers() {
            let headerOptions = []
            headerOptions.push({value: null, text: 'Empty'})

            this.getHeaders().forEach(header => {
                headerOptions.push({value: header.id, text: header.titel})
                if (!this.inputVal.header_id && header.is_default) {
                    this.$set(this.inputVal, 'header_id', header.id)
                }
            })

            return headerOptions
        },
        footers() {
            let footerOptions = []
            footerOptions.push({value: null, text: 'Empty'})
            this.getFooters().forEach(footer => {
                footerOptions.push({value: footer.id, text: footer.titel})
                if (!this.inputVal.footer_id && footer.is_default) {
                    this.$set(this.inputVal, 'footer_id', footer.id)
                }
            })

            return footerOptions
        }
    },
}
</script>